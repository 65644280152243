
import BudouX from '../components/BudouX.vue'
import { ref, defineComponent } from 'vue'
import * as api from '../utils/api'
import Hospital from '../components/Hospital.vue'
import Review from '../components/Review.vue'
import Header from '../components/menu/Public.vue'
import { NoImage } from '@vicons/carbon'
import { useDialog } from 'naive-ui'
import { IHospitalAPIReturn, IHospitalDB, IInterview } from '../../interfaces/hospital'
import { IReview } from '../../interfaces/review'

export default defineComponent({
	components: {
		Hospital,
		Header,
		BudouX,
		Review,
		NoImage,
	},
	data() {
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		const warningDialog = (onPositiveClick: Function) => {
			const d = dialog.success({
				title: '応募しますか？',
				content: '続行を押した後、病院に対してメッセージを送ることでやり取りがスタートします。',
				positiveText: '続行',
				onPositiveClick: async () => {
					d.loading = true
					await onPositiveClick()
					d.loading = false
				},
			})
		}
		return {
			errorDialog,
			warningDialog,
			logined: false,
			admin: api.isAdmin(),
			id: '',
			here: location.href,
			hospital: {} as IHospitalDB,
			reviews: [] as IReview[],
			interviews: [] as IInterview[],
		}
	},
	mounted: function () {
		this.init()
		if (location.hash) {
			const interval = setInterval(function () {
				const element = document.querySelector(location.hash)
				if (element) clearInterval(interval)
				const rect = element?.getBoundingClientRect()
				if (!rect) return
				window.scroll({
					top: rect.top,
					behavior: 'smooth',
				})
			}, 100)
		}
	},
	methods: {
		init: async function () {
			try {
				const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
				if (!fileNames) return false
				const fileName = fileNames[1]
				this.id = fileName
				const data = (await api.get(`/v1/hospital_data/${fileName}`, true)) as IHospitalAPIReturn
				this.hospital = data.hospitalData
				this.interviews = data.interviews
				this.reviews = data.reviews
				document.title = `${data.hospitalData.name} - Vets Index`
				/*
				const head = document.getElementsByTagName('head')[0]
				const meta = document.createElement('meta')
				meta.setAttribute('name', 'description')
				meta.setAttribute('content', )
				head.appendChild(meta)
				*/
			} catch (e: any) {
				this.errorDialog(e, () => history.back())
			}
		},
		getJobStyle: (type: 'fulltime' | 'parttime' | 'temporary') => {
			return { fulltime: '正社員', parttime: 'アルバイト', temporary: '業務委託' }[type]
		},
		getJobType: (type: 'veterinarian' | 'nurse' | 'trimmer' | 'trainer') => {
			return { veterinarian: '獣医', nurse: '看護師', trimmer: 'トリマー', trainer: 'トレーナー' }[type]
		},
	},
})
